import styled from "styled-components"

import { MountingPlateOrderAmount } from "src/components/order/MountingPlateStore/MountingPlateOrderAmount"
import { MountingPlateOrderProvider } from "src/components/order/MountingPlateStore/MountingPlateOrderContext"
import { MountingPlateOrderWizard } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/MountingPlateOrderWizard"
import { MountingPlateStoreTopBar } from "src/components/order/MountingPlateStore/MountingPlateStoreTopBar"
import { useMountingPlateOrderContext } from "src/components/order/MountingPlateStore/useMountingPlateOrderContext"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { FullscreenView } from "src/ui/Modals/FullscreenView"
import { spacing } from "src/ui/spacing"

export function MountingPlateStore() {
  return (
    <MountingPlateOrderProvider>
      <PlateStore />
    </MountingPlateOrderProvider>
  )
}

function PlateStore() {
  const { goBack } = useRouter()
  const { loading } = useMountingPlateOrderContext()
  const { t, langKeys } = useTranslate()

  if (loading) {
    return <LoadingScreen debugInfo={"Loading customer"} />
  }

  return (
    <FullscreenView
      maxWidth="1024px"
      title={<MountingPlateStoreTopBar />}
      onClose={() => goBack({ defaultPath: Routes.Dashboard.location() })}
    >
      <DocumentHead title={t(langKeys.mounting_plate_plural)} />
      <Content>
        <MountingPlateOrderAmount />

        <MountingPlateOrderWizard />
      </Content>
    </FullscreenView>
  )
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XL};

  & > * {
    flex: 1 1 400px;
  }
`
